<script>
	import { _, locale } from 'svelte-i18n';
	import { createEventDispatcher } from 'svelte';
	import FullPageModal from './FullPageModal.svelte';
	import CloseIcon from '../icon/CloseIcon.svelte';
	import MailIcon from '../icon/MailIcon.svelte';
	import VerifyEmail from '../VerifyEmail.svelte';
	import PhoneIcon from '../icon/PhoneIcon.svelte';
	import VerifyPhone from '../VerifyPhone.svelte';
	import LoginProvider from '../LoginProvider.svelte';
	import tippy from 'tippy.js';

	export let label = '';
	export let providers = [];
	export let accountToUse = undefined;
	export let useDifferentManaged = undefined;
	export let contactLoginSuccess = () => {};
	export let handleContactError = () => {};
	export let continueWithEthereumExtension = () => {};
	export let continueWithProvider = () => {};
	// export let continueWithWalletConnect = () => {};

	const dispatch = createEventDispatcher();
</script>

<FullPageModal
	css="stack:-mt-48"
	on:close={() => dispatch('close')}
	dataTest="login-required-modal"
>
	<button class="absolute top-0 right-0 m-6 cursor-pointer" on:click={() => dispatch('close')}>
		<CloseIcon />
	</button>
	<div class="flex flex-col items-start w-full">
		<h1>{label}:</h1>
		<div class="space-y-2 w-full mt-4">
			{#each providers as provider}
				{#if provider.slug === 'email'}
					<div class="relative h-auto btn-border rounded-md hover-none px-4">
						<button class="h-16 w-full flex items-center justify-start">
							<MailIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Email')}
								</span>
								<span>{provider.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyEmail
								email={provider.login_hint}
								{accountToUse}
								{useDifferentManaged}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if provider.slug === 'phone'}
					<div class="relative h-auto btn-border rounded-md hover-none px-4">
						<button class="h-16 w-full flex items-center justify-start">
							<PhoneIcon size="lg" />
							<div
								class="flex flex-col {$locale && $locale.startsWith('ar')
									? 'text-right mr-4'
									: 'text-left ml-4'}"
							>
								<span>
									{$_('Continue with Phone')}
								</span>
								<span>{provider.login_hint}</span>
							</div>
						</button>
						<div class="pb-3 pt-1">
							<VerifyPhone
								phone={provider.login_hint}
								{accountToUse}
								{useDifferentManaged}
								login
								disabled
								on:success={contactLoginSuccess}
								on:error={handleContactError}
							/>
						</div>
					</div>
				{:else if provider.slug === 'ethereum'}
					<div class="relative h-auto btn-border rounded-md hover-none p-2 space-y-2">
						<span class="mb-3 block text-left"
							>{provider.wallet?.name || 'Ethereum'}
							{#if provider.wallet?.display}
								<span use:tippy={{ content: provider.login_hint, placement: 'top' }}>
									({provider.wallet?.display})
								</span>
							{/if}
						</span>
						{#if provider.slug === 'ethereum' && window.ethereum}
							<LoginProvider
								on:ethereum={() =>
									continueWithEthereumExtension({
										info: provider,
										accountToUse,
										useDifferentManaged
									})}
								{provider}
								hideusername
								prefix="Continue with"
							/>
						{/if}
						<!-- Note: Uncomment to show WalletConnect -->
						<!-- <button
                            class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
                            on:click={() => continueWithWalletConnect({info: provider})}
                        >
                            <div class="flex items-center gap-x-4">
                                <img
                                    src="https://cdn.hello.coop/images/walletconnect.svg"
                                    alt="WalletConnect"
                                    class="w-4.5 max-h-[18px]"
                                />
                                <span class="block text-left">
                                    {$_('Continue with {provider}', { values: { provider: 'WalletConnect' } })}
                                </span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="ml-auto stroke-2 group-hover:stroke-3 h-4.5 transform text-charcoal dark:text-[#d4d4d4] opacity-80"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                        </button> -->
					</div>
				{:else}
					<LoginProvider
						login={true}
						on:click={() => {
							continueWithProvider({
								slug: provider.slug,
								body: {
									login_hint: provider.login_hint,
									accountToUse,
									useDifferentManaged
								},
								server: provider.slug === 'mastodon' ? provider.mastodonServer : null
							});
						}}
						{provider}
						prefix="Continue with"
					/>
				{/if}
			{/each}
		</div>
	</div>
</FullPageModal>
